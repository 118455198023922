@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(230, 212, 255);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

body{
  background-color: #008db9;
  background-position: bottom;
}

html,body{
  overflow-x: hidden;
}
